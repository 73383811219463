<div class="container">
  <div class="button-container" *ngIf="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER)">
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="modifyBesharper();">
        <i class="fa-solid fa-pen"></i>
        &nbsp;
        <span>{{eTextStrings.MODIFY}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="deleteBesharper();">
        <i class="fa-solid fa-trash"></i>
        &nbsp;
        <span>{{eTextStrings.DELETE}}</span>
      </button>
    </div>
  </div>

  <div class="card-detail">
    <mat-card>
      <mat-card-content>
        <p><i class="fa-regular fa-id-card"></i><b>beSharper: </b>{{beSharper?.name}} {{beSharper?.surname}}</p>
        <p><i class="fa-solid fa-id-card"></i><b>codice fiscale: </b>{{beSharper?.cf}}</p>
        <p><i class="fa-solid fa-envelope"></i><b>email: </b>{{beSharper?.email}}</p>
        <p><i class="fa-solid fa-cake-candles"></i><b>compleanno: </b>{{formatDate(beSharper?.birthDate, true)}}</p>
        <p *ngIf="[RoleEnum.ADMIN, RoleEnum.HR, RoleEnum.HR_REPORTS].includes($any(userRole))"><i
            class="fa-solid fa-venus-mars"></i><b>sesso: {{beSharper?.gender}}</b></p>
        <p><i class="fa-solid fa-school"></i><b>titolo di studio: </b>{{beSharper?.school || 'Non presente.' }}</p>
        <p><i class="fa-solid fa-user"></i><b>data di assunzione: </b>{{formatDate(beSharper?.hiringDate, true) || 'Non
          presente.' }}</p>
        <p><i class="fa-solid fa-user"></i><b>data di cessazione: </b>{{formatDate(beSharper?.firingDate, true) || 'Non
          presente.' }}</p>
        <p>
          <i class="fa-solid fa-user"></i><b>stato: </b>
          <span [class]="isBesharperActive() ? 'status active' : 'status ceased'">{{isBesharperActive()
            ? 'ATTIVO'
            : 'CESSATO' }}</span>
          <span *ngIf="isThereAnActiveContract() === isFired()" class="status app-notification-warning">
            C'è una discrepanza tra il valore nel campo stato del beSharper e il valore check per il walfare!
          </span>
        </p>
        <p *ngIf="beSharper?.customMaximal">
          <i class="fa-duotone fa-solid fa-money-bill-1-wave"></i><b>massimale custom:
          </b>{{formatMoney(beSharper?.customMaximal)}}
          &nbsp;
          <span class="label-small"
            [matTooltip]="'Puoi attivare il massimale standard cancellando il valore dal besharper'">Massimale Custom
            Attivo!</span>
        </p>

        <p>
          <i class="fa-solid fa-location-dot"></i><b> indirizzo: </b>
          <ng-container *ngIf="beSharper?.address?.street">
            {{beSharper?.address?.street}}&nbsp;
            {{beSharper?.address?.number}},&nbsp;
            {{beSharper?.address?.city}}&nbsp;({{beSharper?.address?.province}}),
            {{beSharper?.address?.zipCode}}&nbsp;-&nbsp;{{beSharper?.address?.country}}
          </ng-container>
          <ng-container *ngIf="!beSharper?.address?.street">Non ancora inserito completamente,
            verificare.</ng-container>
        </p>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="contracts detail-table" *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
    <mat-card>
      <mat-card-content>
        <div class="detail-card-header">
          <div class="v-centered-row space-between eye-toggle-container">
            <h2>Contratti</h2>
            <app-eye-toggle [(visible)]="contractSensitiveDataVisible"></app-eye-toggle>
          </div>
          <div class="align-right create-button">
            <button mat-raised-button color="primary" (click)="createContract();">
              <i class="fa-solid fa-plus"></i>
              &nbsp;
              <span>{{eTextStrings.CONTRACT_CREATE}}</span>
            </button>
          </div>
        </div>
        <app-table-ui [datasource]="contractDataSource" [backendUrl]="contractBackendUrl" [defaultActive]="'active'"
          [defaultDirection]="'desc'" [tableDefinitions]="contractTableDefinitions" [pageSize]="10"
          [pageSizeOptions]="[10,20,30]" [filterObservable]="filterObservable" [mapper]="contractMapper"
          [showPaginator]=true (rowClicked)="areaRowClicked($event,'contract-detail');">
        </app-table-ui>
        <div *ngIf="!contractDataSource">{{eTextStrings.CONTRACT_NON_ASSIGNED}}</div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="welfare detail-table" *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
    <mat-card>
      <mat-card-content>
        <div class="detail-card-header">
          <div class="v-centered-row space-between eye-toggle-container">
            <h2>Welfare</h2>
            <app-eye-toggle [(visible)]="welfareSensitiveDataVisible"></app-eye-toggle>
          </div>
          <h4>Residuo: <span [innerHtml]="checkMaximalValue()"></span></h4>
          <div class="align-right create-button">
            <button mat-raised-button color="primary" (click)="changeMaximal();">
              <span>{{eTextStrings.WELFARE_CHANGE_MAXIMAL}}</span>
            </button>
            &nbsp;
            &nbsp;
            &nbsp;
            <button mat-raised-button color="primary" (click)="createWelfare();">
              <i class="fa-solid fa-plus"></i>&nbsp;<span>{{eTextStrings.WELFARE_CREATE}}</span>
            </button>
          </div>
        </div>
        <app-table-ui [datasource]="welfareDataSource" [backendUrl]="welfareBackendUrl"
          [tableDefinitions]="welfareTableDefinitions" [pageSize]="10" [pageSizeOptions]="[10,20,30]"
          [filterObservable]="filterObservable" [mapper]="welfareMapper" [showPaginator]=true
          (rowClicked)="areaRowClicked($event, 'welfare-detail');">
        </app-table-ui>
        <div *ngIf="!welfareDataSource">{{eTextStrings.WELFARE_NON_ASSIGNED}}</div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="bonus detail-table" *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
    <mat-card>
      <mat-card-content>
        <div class="detail-card-header">
          <div class="v-centered-row space-between eye-toggle-container">
            <h2>Bonus</h2>
            <app-eye-toggle [(visible)]="bonusSensitiveDataVisible"></app-eye-toggle>
          </div>
          <div class="align-right create-button">
            <button mat-raised-button color="primary" (click)="createBonus();">
              <i class="fa-solid fa-plus"></i>
              &nbsp;
              <span>{{eTextStrings.BONUS_CREATE}}</span>
            </button>
          </div>
        </div>
        <app-table-ui [datasource]="bonusDataSource" [backendUrl]="bonusBackendUrl"
          [tableDefinitions]="bonusTableDefinitions" [pageSize]="10" [pageSizeOptions]="[10,20,30]"
          [filterObservable]="filterObservable" [mapper]="bonusMapper" [showPaginator]=true
          (rowClicked)="areaRowClicked($event, 'bonus-detail');">
        </app-table-ui>
        <div *ngIf="!welfareDataSource">{{eTextStrings.BONUS_NON_ASSIGNED}}</div>
      </mat-card-content>
    </mat-card>
  </div>

  <!--<div class="equipment detail-table" *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
    <mat-card>
      <mat-card-content>
        <div class="detail-card-header">
          <h2>Dotazioni</h2>
          <div class="align-right create-button">
            <button mat-raised-button color="primary" (click)="createEquipment();">
              <i class="fa-solid fa-plus"></i>
              &nbsp;
              <span>{{'EQUIPMENT.CREATE' | i18next}}</span>
            </button>
          </div>
        </div>
        <app-table-ui
          [datasource]="equipmentDataSource"
          [backendUrl]="equipmentBackendUrl"
          [tableDefinitions]="equipmentTableDefinitions"
          [pageSize]="10"
          [pageSizeOptions]="[10,20,30]"
          [filterObservable]="filterObservable"
          [mapper]="equipmentMapper"
          [showPaginator]=false
          (rowClicked)="areaRowClicked($event, 'equipment-detail')"
          >
        </app-table-ui>
        <div *ngIf="!equipmentDataSource">{{'BONUS.NON_ASSIGNED' | i18next}}</div>
      </mat-card-content>
    </mat-card>
  </div>-->
</div>