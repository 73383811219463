import { AfterViewInit, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Constants } from '../../../core/constants/constants';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { TextStrings } from "../../../core/constants/text-strings";
import { environment } from "../../../../environments/environment";
import moment from 'moment';

@Component({
  selector: 'app-admin-panel-create-modify-custom-holiday',
  templateUrl: './admin-panel-create-custom-holiday.component.html',
  styleUrls: ['./admin-panel-create-custom-holiday.component.scss']
})
export class AdminPanelCreateCustomHolidayComponent implements AfterViewInit{
  loading = false;
  customHolidaysUrl = `${environment.cognito.apiEndpoint}${Constants.customHolidaysApiPath}`;
  months = [
    { index: 1, name: 'Gennaio' },
    { index: 2, name: 'Febbraio' },
    { index: 3, name: 'Marzo' },
    { index: 4, name: 'Aprile' },
    { index: 5, name: 'Maggio' },
    { index: 6, name: 'Giugno' },
    { index: 7, name: 'Luglio' },
    { index: 8, name: 'Agosto' },
    { index: 9, name: 'Settembre' },
    { index: 10, name: 'Ottobre' },
    { index: 11, name: 'Novembre' },
    { index: 12, name: 'Dicembre' }
  ];
  eTextStrings = TextStrings;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    day: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(31)]),
    month: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(12)]),
    addOrRemove: new FormControl('add', Validators.required),
  }, this.dateValidator);

  dateValidator(control: AbstractControl): ValidationErrors | null {
    if (!(control instanceof FormGroup)) {
      return null;
    }

    const day = control.get('day')?.value?.toString().padStart(2, '0');
    const month = control.get('month')?.value?.toString().padStart(2, '0');
    const momentDate = moment(`${month}-${day}`, 'MM-DD');
    if (!momentDate.isValid()) {
      return { invalidDate: true };
    }
    return null;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateCustomHolidayComponent>
  ) {
    console.log("THIS DATA: ", this.data.data);
    if(this.data.data && this.data.data?.name) {
      this.form.controls.name.setValue(this.data.data?.name);
      this.form.controls.day.setValue(Number.parseInt(this.data.data?.date.split('-')[1]));
      this.form.controls.month.setValue(Number.parseInt(this.data.data?.date.split('-')[0]));
      this.form.controls.addOrRemove.setValue(this.data.data?.add_or_remove);
    }
  }

  ngAfterViewInit(): void {}

  async handleSubmit(): Promise<void> {
    this.loading = true;
    try {
      await this.createHoliday();
      this.providerService.utilService.showMessage(TextStrings.SUCCESS, LogLevel.success);
      this.dialogRef.close();
    } catch (error) {
      this.providerService.utilService.showMessage(error as any, LogLevel.error);
    } finally {
      this.loading = false;
    }
  }

  private async createHoliday(): Promise<void> {
    const requestMapper = new SimpleMapper();
    this.populateRequestBody(requestMapper);
    if(this.data.data?.name) {
      await this.providerService.networkService.put(this.customHolidaysUrl + "/" + this.data.data?.id, requestMapper, new SimpleMapper());
    } else {
      await this.providerService.networkService.post(this.customHolidaysUrl, requestMapper, new SimpleMapper());
    }
  }

  private populateRequestBody(mapper: SimpleMapper): void {
    const paddedMonth = this.form.value.month?.toString().padStart(2, '0');
    const paddedDay = this.form.value.day?.toString().padStart(2, '0');

    mapper.fillFromJson({
      name: this.form.value.name,
      date: `${paddedMonth}-${paddedDay}`,
      add_or_remove: this.form.value.addOrRemove,
    });
  }
}
