import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProviderService } from '../../../core/provider.service';
import { Constants, COUNTRIES } from "../../../core/constants/constants";
import { BeSharperPostMapper } from '../../../mappers/besharper.mapper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { IMapper } from '../../../models/i-mapper';
import { LogLevel } from '../../../models/log-level';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { ModeEnum } from '../../../core/constants/enums';
import RoleEnum from "../../../models/role.enum";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";

@Component({
  selector: 'app-create-modify-besharper',
  templateUrl: './create-modify-besharper.component.html',
  providers: [MatDatepickerModule],
  styleUrl: './create-modify-besharper.component.scss',
})
export class CreateModifyBesharperComponent implements OnInit {
  loading = false;
  mode: ModeEnum = ModeEnum.CREATE;
  backendUrlBesharper = environment.cognito.apiEndpoint + Constants.besharperApiPath;
  user?: any;
  parentFromEnabled: boolean = false;
  eTextStrings = TextStrings;
  countries = COUNTRIES;
  transparentUserMaximal = null;

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
    cf: new FormControl('', [Validators.required, Validators.maxLength(16), Validators.minLength(16)]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])'
      ),
    ]),
    birthDate: new FormControl(new Date(), [Validators.required]),

    school: new FormControl(''),

    street: new FormControl(''),
    number: new FormControl(''),
    city: new FormControl(''),
    zipCode: new FormControl(''),
    province: new FormControl(''),
    country: new FormControl('Italy'),

    parentFrom: new FormControl(new Date()),
    gender: new FormControl("", [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateModifyBesharperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
    if (this.data['mode'] === 'modify') {
      this.mode = ModeEnum.MODIFY;
    } else if (this.data['mode'] === 'create') {
      this.mode = ModeEnum.CREATE;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    if (this.data['mode'] == 'modify') {
      this.mode = ModeEnum.MODIFY;
      this.user = this.data['user'];
      this.populateFormForModify(this.data['user']);
    }
    this.loading = false;
  }

  formValid(): boolean {
    for (let [key, control] of Object.entries(this.form.controls)) {
      if (key === 'parentFrom' && !this.parentFromEnabled) {
        continue;
      }

      if (key === 'gender' && control.value === '') {
        return false;
      }

      if (!control.valid) {
        return false;
      }
    }

    return true;
  }

  isCreateMode(): boolean {
    return this.mode === ModeEnum.CREATE;
  }

  isModifyMode(): boolean {
    return this.mode === ModeEnum.MODIFY;
  }

  async addUser(addAnother?: boolean): Promise<void> {
    this.loading = true;

    const responseMapper = new SimpleMapper();
    const postMapper = new BeSharperPostMapper();

    try {
      this.fillMapper(postMapper);
      await this.providerService.networkService
        .post(this.backendUrlBesharper, postMapper, responseMapper)
        .then(() => {
          this.providerService.utilService.showMessage(TextStrings.BESHARPER_CREATE_SUCCESS, LogLevel.success);
          this.loading = false;
          this.form.reset();
          this.parentFromEnabled = false;
          if (!addAnother) {
            this.dialogRef.close();
          }
        })
        .catch((error) => {
          //console.error(error);
          this.providerService.utilService.showMessage(error, LogLevel.error);
          this.loading = false;
        });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  async modifyUser(): Promise<void> {
    this.loading = true;
    const postMapper = new BeSharperPostMapper();
    const mapper = new SimpleMapper();
    try {
      this.fillMapper(postMapper);
      this.providerService.networkService
        .put(this.backendUrlBesharper + '/' + this.user['id'], postMapper, mapper)
        .then(() => {
          //console.debug(postMapper);
          this.providerService.utilService.showMessage('beShaper modificato con successo', LogLevel.success);
          this.dialogRef.close();
          this.loading = false;
        })
        .catch((e) => {
          //console.log(e);
          this.providerService.utilService.showMessage(e, LogLevel.error);
          this.loading = false;
        });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      //console.error(e);
      this.loading = false;
    }

    //console.log(mapper);
  }

  private populateFormForModify(user: any) {
    //console.log(data);
    this.form.controls.name.setValue(user['name']);
    this.form.controls.surname.setValue(user['surname']);
    this.form.controls.email.setValue(user['email']);
    this.form.controls.cf.setValue(user['cf']);
    this.form.controls.birthDate.setValue(new Date(user['birthDate']));

    this.form.controls.school.setValue(user['school']);
    this.transparentUserMaximal = user['customMaximal'];


    if (user["address"]) {
      this.form.controls.street.setValue(user['address']['street']);
      this.form.controls.number.setValue(user['address']['number']);
      this.form.controls.city.setValue(user['address']['city']);
      this.form.controls.zipCode.setValue(user['address']['zipCode']);
      this.form.controls.province.setValue(user['address']['province']);
      this.form.controls.country.setValue(user['address']['country']);
      this.form.controls.gender.setValue(user['gender']);
    }

    this.form.controls.parentFrom.setValue(new Date(this.user['parentFrom']));
    this.parentFromEnabled = this.user['parentFrom'] !== undefined && this.user['parentFrom'] !== null;
  }

  fillMapper(mapper: IMapper): void {
    let firingDate = null;

    mapper.fillFromJson({
      name: this.form.controls.name.value,
      surname: this.form.controls.surname.value,
      email: this.form.controls.email.value,
      cf: this.form.controls.cf.value,
      birthDate: this.providerService.utilService.formatDate(this.form.controls.birthDate.value!),
      role: RoleEnum.NOT_YET_ASSIGNED,
      parentFrom: this.parentFromEnabled
        ? this.providerService.utilService.formatDate(this.form.controls.parentFrom.value!)
        : null,
      school: this.form.controls.school.value,
      customMaximal: this.transparentUserMaximal,
      gender: this.form.controls.gender.value,
      address: {
        street: this.form.controls.street.value,
        number: this.form.controls.number.value,
        city: this.form.controls.city.value,
        zipCode: this.form.controls.zipCode.value,
        province: this.form.controls.province.value,
        country: this.form.controls.country.value,
      },
    });
  }
}
