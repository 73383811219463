import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { TableService } from './table.service';
import { OrderEnum } from '../../../models/filter.model';
import { IMapper } from '../../../models/i-mapper';
import { BesharperState } from "../../constants/enums";

export class TableDataSource<T> implements DataSource<T> {
  public loading$: Observable<boolean>;

  private tableSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private tableService?: TableService;

  constructor() {
    this.loading$ = this.loadingSubject.asObservable();
  }

  setTableService(tableService: TableService): void {
    this.tableService = tableService;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connect(_: CollectionViewer): Observable<T[]> {
    return this.tableSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disconnect(_: CollectionViewer): void {
    this.tableSubject.complete();
    this.loadingSubject.complete();
  }

  async loadElements(
    url: string,
    _parentId: number,
    filter = '',
    daysOfTheWeek = '00000',
    activeCeasedStatus = BesharperState.ALL,
    mobileWindow = false,
    onlyMe: boolean,
    onlySupervisor: boolean,
    sortItem = '',
    sortDirection: OrderEnum = OrderEnum.asc,
    pageIndex = 0,
    pageSize = 3,
    mapper: IMapper,
    role?: string
  ): Promise<void> {
    if (this.tableService) {
      try {
        this.loadingSubject.next(true);

        const resMapper = await this.tableService.find(
          url,
          filter,
          daysOfTheWeek,
          activeCeasedStatus,
          mobileWindow,
          onlyMe,
          onlySupervisor,
          sortItem,
          sortDirection,
          pageIndex,
          pageSize,
          mapper,
          role
        );
        this.tableSubject.next(resMapper.elements);
      } catch (error: any) {
        this.tableSubject.next([]);
        throw new Error(error);
      } finally {
        this.loadingSubject.next(false);
      }
    }
  }

  public getItems() : T[] {
    return this.tableSubject.value;
  }
}
