<div class="create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="create-modify-form">
    <h1 class="create-modify-title" *ngIf="isModifyMode()">
      {{eTextStrings.MODIFY}}{{' '}} Business Unit
    </h1>
    <h1 class="create-modify-title" *ngIf="!isModifyMode()">
      {{eTextStrings.CREATE}}{{' '}} Business Unit
    </h1>
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="inserisci il nome del tipo di ooo" formControlName="name">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Codice</mat-label>
        <input matInput placeholder="inserisci il codice identificativo del tipo di ooo" formControlName="code">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>N° giorni precedenti alla richesta</mat-label>
        <input matInput placeholder="inserisci il n° di giorni precedenti alla richesta" formControlName="offsetDays"
          type="number">
      </mat-form-field>

      <mat-checkbox [color]="'secondary'" formControlName="hasAttachment"
        [checked]="form.controls.hasAttachment.value">Allegato Necessario?</mat-checkbox>
      <mat-checkbox [color]="'secondary'" formControlName="onlyWorkDays"
        [checked]="form.controls.onlyWorkDays.value">Solo GG lavorativi</mat-checkbox>
      <mat-checkbox [color]="'secondary'" formControlName="showTypeInCalendar"
        [checked]="form.controls.onlyWorkDays.value">Mostra tipo negli eventi sul calendario</mat-checkbox>
      <mat-checkbox [color]="'secondary'" formControlName="needApproval"
                    [checked]="form.controls.needApproval.value">Processo Approvativo?</mat-checkbox>

      <mat-form-field appearance="fill">
        <mat-label>Priorità di ordinamento (più alto è il valore, più in basso compare nella lista)</mat-label>
        <input matInput placeholder="inserisci il valore di priorità" formControlName="sortKey" type="number" [min]="1"
          [max]="9999">
      </mat-form-field>

      <div class="create-modify-button">
        <button color="{{form.valid && !loading ? 'primary' : 'disabled'}}" disabled="{{form.invalid || loading}}"
          mat-raised-button (click)="sendRequest()">{{eTextStrings.CONFIRM}}</button>
        <button color="primary" mat-raised-button (click)="dialogRef.close()">{{eTextStrings.CANCEL}}</button>
      </div>
    </form>
  </div>
</div>
