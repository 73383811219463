<div class="table-header-container">
  <div class="table-header-text">
    <div class="back-button-container">
      <button mat-raised-button color="primary" [routerLink]="['../']"><i matTooltip="{{eTextStrings.ADMINPANEL_BACK}}"
          class="fa fa-arrow-left" aria-hidden="true"></i></button>
    </div>
    <i class="far fa-file-contract"></i>
    <h1>Setup Festività</h1>
  </div>
</div>

<div class="cards-container">
  <mat-accordion>
    <mat-expansion-panel (opened)="panelExpanded=true" (closed)="panelExpanded=false" [expanded]="panelExpanded">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          {{eTextStrings.CUSTOM_HOLIDAY_TITLE}}
        </mat-panel-title>
        <mat-panel-description>
          <div class="card-header-actions">
            <button mat-raised-button color="primary" (click)="createCustomHoliday()">{{eTextStrings.CREATE}} {{' '}}
              {{eTextStrings.CUSTOM_HOLIDAY_TITLE}}</button>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        In questa pagina è possibile aggiungere o togliere delle Festività dal calcolo del report sulle Ferie/Permessi.<br>
        <b>Add</b>: Aggiunge la festività alla lista di festività di beSharp. Significa che il giorno in questione è considerato festività Aziendale e non viene sottratto dalle ferie del dipendente.<br>
        <b>Remove</b>: Rimuove la festività dalla lista di festività di beSharp. Significa che il giorno in questione non è considerato festività Aziendale e viene sottratto dalle ferie del dipendente.
      </p>
      <app-table-ui
        [datasource]="tableDataSource"
        [backendUrl]="customHolidaysListUrl"
        [tableDefinitions]="customHolidaysTableDefinitions"
        [actionDefinitions]="customHolidaysActionDefinitions"
        [pageSize]="9999"
        [pageSizeOptions]="[9999]"
        [filterObservable]="filterObservable"
        [mapper]="simpleMapper"
        [showPaginator]="false"
        (rowClicked)="openModifyDialog($event);">
        [filterObservable]="filterObservable">
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-container *ngIf="!loading">
  <br>
</ng-container>
